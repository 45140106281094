import React from "react";

import {Container, Row, Col } from "react-bootstrap";
/**
 * A unified component for creating the website sections
 * @param {*} props Available properties: bg, color, padding, additionalClasses.
 * @param {jsx} props.children Nested components should be wrapped in <Col>, because their parent shall be <Row>
 */
const Section = ( props ) => {

    let bg = props.bg ? "bg-"+ props.bg : "bg-gray";
    let text = props.color ? "text-"+ props.color : "text-dark";
    let hp = props.padding ? `pt-${props.padding} pb-${props.padding}` : "pt-5 pb-5";

    const classesList = `${hp} ${bg} ${text} ${props.additionalClasses}`;
    
    return (
        <section className={ classesList }>
            <Container id={props.id}>
                {props.title &&
                    <Row>
                        <Col>
                            <h2 className={text + " text-center pb-5 text-uppercase"}>{props.title}</h2>
                        </Col>
                    </Row>
                }
                <Row className={props.rowClasses}>
                    {props.children}
                </Row>

            </Container>
        </section>
    );
}

export default Section;