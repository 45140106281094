import React from "react";

import { Col } from "react-bootstrap";

import SequencePartial from "./SequencePartial";
/**
 * A numbered sequence of certain steps
 * @param {props.step} props 
 */
const Sequence = ( props ) => {
    let iterator = 0;
    // let number = <div className="sequence-partial-number">{iterator}</div>;

    return(
        <>
            { props.data &&
                props.data.map((step)=>{
                    iterator++;
                    return (
                        <Col key={step.title} xs={12} md={6} lg={props.lg ? props.lg : 4} className="pb-5">
                            <SequencePartial
                                intro={<div className="sequence-partial-number">{iterator}</div>} 
                                title={step.title} 
                                content={step.content}
                            />
                        </Col>
                    );
                })
            }
        </>
    );
}

export default Sequence;