import React from "react";
import { Link } from "react-router-dom"
// import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Card, Button, Col } from "react-bootstrap";

class RegistrationCard extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            collapsed: true,
        }
    }

    handleToggle(){
        const currentState = this.state.collapsed ? false: true;
        this.setState({collapsed: currentState });
    }

    scrollTop(){
        window.scrollTo(0,0);
    }

    render() {

        let collapsedClass = this.state.collapsed ? "card-text collapsible collapsed" : "card-text collapsible expanded";

        return(
            <Col xs={12} md={5} lg={4} className="py-2 card-holder">
            <Card bg="primary">
                {this.props.header && <Card.Header>{this.props.header}</Card.Header>}
                <Card.Body>
                    <Card.Title>{this.props.title}</Card.Title>
                    <Card.Text as="div">
                    {this.props.content}
                    </Card.Text>
                    <div className={collapsedClass}>
                            {this.props.lineup}
                    </div>
                    
                </Card.Body>
                
                    
                    {this.props.buttonActive &&
                        <Card.Body>
                            <Button variant="light" onClick={()=>this.handleToggle()}>{this.state.collapsed ? this.props.buttonActive : this.props.buttonInactive}</Button>
                        </Card.Body>
                        }
                        {this.props.link &&
                            <Card.Body>
                            <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                                <Button variant="light">{this.props.linkText}</Button>
                            </a>
                            </Card.Body>
                        }
                        {this.props.to &&
                            <Card.Body>
                            <Link to={this.props.to} onClick={()=>this.scrollTop()}>
                                <Button variant="light">Více informací</Button>
                            </Link>
                            </Card.Body>
                        }
                    
                
            </Card>
            </Col>
        )
    };
}

// export default RegistrationCard;

const RegistrationCards = ( props ) => {
    return(
        <>
            {props.data &&
                props.data.map((card)=>{
                    return(
                        <RegistrationCard key={card.id} {...card} />
                    )
                })
            }
        </>
    );
}

export default RegistrationCards;