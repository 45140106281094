import React from "react";

import { FaRegCircle } from 'react-icons/fa';
import { FaCircle } from 'react-icons/fa';

// FaRegCircle FaCircle

class StandStatus extends React.Component {

    constructor( props ) {
        super( props );

        this.state = {
            isValid: false,
        }
    }
    
    componentDidMount(){
        if (this.props.capacity && this.props.freePositionsCount) {
            this.setState({ isValid: true });
        } else {
            this.setState({ isValid: false });
        }
    }

    render() {

        const toBorrow = this.props.AvailableScooters;
        const toReturn = this.props.FreePositionsCount;

        const positive = [];
        for ( let i=0; i < toBorrow;i++ ) {
            let kid = "key" + i;
            positive.push(<FaCircle key={kid} className="text-info"/>);
        }
        const negative = [];
        for ( let i=0; i < toReturn;i++ ) {
            negative.push(<FaRegCircle key={"stand-" + i} className="text-danger"/>);
        }

        return (

            <div className="StandStatus">
                { positive.map(item=>{
                    return item
                }) }
                { negative.map(item=>{
                    return item
                }) }
            </div>

        );

    }
}

export default StandStatus