import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './styles/App.scss';
import "animate.css/animate.min.css";
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import JavascriptTimeAgo from 'javascript-time-ago';
import cs from 'javascript-time-ago/locale/cs';
JavascriptTimeAgo.locale(cs);

ReactDOM.render(<HashRouter>
        <App />
    </HashRouter>, 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
