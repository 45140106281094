import React from "react";
// import { Link } from "react-router-dom";
import { Nav, Col } from "react-bootstrap";

import Layout from "../layout/Layout";
import Partners from "../content/Partners";
import Section from "../layout/Section";
// import RegistrationCard from "../content/RegistrationCard";

// import pilsen from "../data/images/pilsen.jpg";
import kolobrnda from "../data/images/kolobrnda.png";
import RegistrationCards from "../content/RegistrationCard";

const Home = () => {

    const menu = [
        <Nav.Link key="zlo" href="#/centrum">Centrum Plzně</Nav.Link>,
        <Nav.Link key="dobro" href="#/kampus">Kampus ZČU</Nav.Link>
    ];

    const registration = [
        {
            id: "regferw",
            // header:"Plzeňská karta",
            title: <>Chci jezdit v centru Plzně</>,
            // content: <p>V centru máme aktuálně 5 stanovišť s celkem X koloběžkami</p>,
            // link: "https://portal.zcu.cz/portal/ja/jis/kolobezky.html",
            to: "/centrum"
        },
        {
            id: "fergyxc",
            // header: "Plzeňská karta",
            title: <>Chci jezdit u kampusu ZČU</>,
            // content: <p>U kampusu máme aktuálně XX koloběžek na 3 stanovištích.</p>,
            // link: "https://portal.zcu.cz/portal/ja/jis/kolobezky.html",
            to: "/kampus"
        }
    ];

    return (
        <Layout links={menu} title="Scoobike Sharing" front={true}>
            <Section additionalClasses="intro-bg" rowClasses="justify-content-center">
                <RegistrationCards data={registration} />
            </Section>
            <Partners />
            <Section bg="light" title="Co je to Scoobike Sharing?">
                <Col xs={12} lg={6}>
                    <p>Scoobike sharing je první systém sdílení koloběžek na světě! Všichni jistě znáte různé formy bikesharingu, neboli sdílení kol, které jsou rozmístěny po městech a lidé si je libovolně půjčují a vrací je zpět. Fungujeme stejně. Koloběžku si jednoduše vypůjčíte ve stojanu a v nejbližší stojanu opět vrátíte. Registrace je jednoduchá a Scoobike sharing je tady pro všechny!</p>
                    <p>Zatím máme jen dva stojany, ale nebojte, jen co se seznámíme, budou další a další!</p>
                </Col>
                <Col xs={12} lg={6}>
                    <img src={kolobrnda} alt="Koloběžka" className="fit-width"/>
                </Col>
            </Section>

        </Layout>
    );
}

export default Home;