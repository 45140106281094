import React from "react";

import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";

const Header = (props) => {
    return(
        <Navbar
            variant="light"
            bg="light"
            expand="lg"
            sticky="top"
            id="site-navbar"
            collapseOnSelect={true}
        >
            <Container>
                <Link to="/">
                    <Navbar.Brand as="span">
                        { ! props.front &&
                            <FaAngleLeft style={{paddingBottom: "3px"}}/>
                        }
                        <strong className="text-transform-uppercase">{props.title}</strong>
                    </Navbar.Brand>
                </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto" activeKey={props.pageURL && props.pageURL}>
                            { props.links ? props.links : false }
                        </Nav>
                    </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;