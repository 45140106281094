import React from "react";
import { Link } from "react-router-dom";
import {  Col } from "react-bootstrap";
// import { ScrollTo } from "react-scroll-to";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Layout from "../layout/Layout";
import Section from "../layout/Section";
import Sequence from "../content/Sequence";
import Bulbs from "../content/Bulb";
import RegistrationCards from "../content/RegistrationCard";
import Tips from "../content/Tips";
import Obligations from "../content/Obligations";

import Map from "../components/Map";

const Downtown = () => {

    // const registrationReference = React.createRef();

    const sequence = [
        {
            title: "Přilož kartu",
            content: <>
                <p>Po dobu 20 sekund se rozsvítí zámky koloběžek</p>
                <div>
                <div className="sequence-partial pb-3">
                    <div className="sequence-partial-image">
                        <div className="bulb-button variant-green mr-2"></div>
                    </div>
                    <div className="sequence-partial-text">
                        <h6 className="sequence-partial-title pt-2">Funkční koloběžka</h6>
                    </div>
                </div>
                <div>
                    <div className="sequence-partial">
                        <div className="sequence-partial-image">
                            <div className="bulb-button variant-red mr-2"></div>
                        </div>
                        <div className="sequence-partial-text">
                            <h6 className="sequence-partial-title pt-2">Porouchaná koloběžka</h6>
                        </div>
                    </div>
                </div>
                </div>
            </>
        },
        {
            title: "Stiskni tlačítko u koloběžky",
            content: <>
                <p>Máš 10 sekund na odebrání koloběžky ze stojanu</p>
            </>
        },
        {
            title: "Vyjmi koloběžku",
            content: <p>A užijte si jízdu!</p>
        },
        
    ];

    const bulbs = [
        {
            color: "blue",
            title: "Vracíte koloběžku, která je v pořádku?",
            content: <>
                <p>Vraťte ji do modře svítícího stojanu. Po vrácení musí zámek koloběžky zezelenat.</p>
                <p>Děkujeme!</p>
            </>
        },
        {
            color: "red",
            title: "Vracíš porouchanou koloběžku?",
            content: <>
                <p>Vraťte ji rovněž do modře svítícího stojanu a podržte tlačítko zámku do té doby, než začne svítit červeně.</p><p>Děkujeme!</p>
            </>
        }
    ];

    const registration = [
        {
            id: "jedna",
            title: <>Mám nabitou Plzeňskou kartu předplacenou alespoň na 90 dní</>,
            lineup_: "V hodnotě 300 Kč, nebo čtvrtletní, půlroční či roční předdplatné",
            content: <ol>
                <li>Najděte nejbližší Scoobike Sharing s koloběžkami.</li>
                <li>Přiložte Plzeňskou kartu na pole po dobu 2 vteřin.</li>
                <li>registrace je hotová a můžete jezdit!</li>
            </ol>,
            buttonActive_: "Jak se registrovat",
            buttonInactive_: "Jdu na to!"
        },
        {
            id: "druha",
            title: <>Nemám nabitou Plzeňskou&nbsp;kartu</>,
            content: "Registraci bez Plzeňské karty spustíme již brzy! Nenechte si to uniknout!",
            link: "https://www.facebook.com/scoobike/",
            linkText: "Sledujte náš Facebook"
        }
    ];

    const menu = [
        <AnchorLink key="mapa" href='#mapa' offset="100" className="nav-link">Mapa</AnchorLink>,
        <AnchorLink key="reg" href='#registrace' offset="100" className="nav-link">Registrace</AnchorLink>,
        <AnchorLink key="jak" href='#jak-to-funguje' offset="100" className="nav-link">Jak to funguje</AnchorLink>,
        <AnchorLink key="jezdit" href='#jak-jezdit' offset="100" className="nav-link">Tipy pro jízdu</AnchorLink>
    ];

    return (
        <Layout links={menu} title="Centrum Plzně">
            <Map id="mapa" />
            <Section id="registrace" title="Free Registrace" rowClasses="justify-content-center">
                <RegistrationCards data={registration} />
                <Col xs={12} className="pt-5 text-secondary"><small>Registrací souhlasíte s obchodními podmínkami, jejich plné znění naleznete na <Link to="/podminky">tomto webu</Link>. Zapůjčením přebíráte zodpovědnost za
stav koloběžky, a to včetně případného zničení, ztráty, poškození či znehodnocení koloběžky
nad rámec běžného opotřebení.</small></Col>
            </Section>
            <Section id="jak-to-funguje" title="Výpůjčka" bg="light">
                <Col xs={12} className="text-center">
                    <div className="p-3 bg-white text-primary font-weight-bold pseudo-button">
                        Výpůjční doba: pondělí - neděle 7:00 - 20:00
                    </div>
                    <div className="py-5">
                    Máte registraci? Hurá do toho! Koloběžku si můžete vypůjčit zdarma po dobu 30 minut.
                    </div>
                </Col>
                <Sequence data={sequence} />
            </Section>
            <Section id="vraceni" title="Vrácení">
                <Col xs={12} className="text-center pb-5">
                    <div className="p-3 bg-primary text-white font-weight-bold pseudo-button">
                        Výpůjční doba: pondělí - neděle 7:00 - 20:00
                    </div>
                </Col>
                <Bulbs data={bulbs}/>
            </Section>
            <Section id="jak-jezdit" title="Jak správně jezdit na koloběžce?" bg="light">
                <Tips />
                <Col xs={12} className="text-center pt-5">
                    <div className="p-3 bg-white text-primary font-weight-bold pseudo-button">
                        Děkujeme!
                    </div>
                </Col>
            </Section>
            <Obligations />
        </Layout>
    );
}

export default Downtown;