import React from "react";

import ScrollAnimation from 'react-animate-on-scroll';

const SequencePartial = ( props ) => {

    return (
        <ScrollAnimation animateIn="fadeInUp" className="sequence-partial pt-xs-3 pt-md-4">
            {props.intro &&
                <div className="sequence-partial-intro">{props.intro}</div>
            }
            <div className="sequence-partial-text">
                {props.title &&
                    <h6 className="sequence-partial-title pt-2 pb-4">{props.title}</h6>
                }
                {props.content &&
                    <div className="sequence-partial-content">{props.content}</div>
                }
            </div>
            
        </ScrollAnimation>
    );

}

export default SequencePartial;