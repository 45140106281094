import React from "react";

import { Col } from "react-bootstrap";

import Section from "../layout/Section";

import zcu from "../data/logos/loga_zcu.jpg";
import kostka from "../data/logos/loga_kostka.jpg";
import ksvetu from "../data/logos/loga_ksvetu.jpg";
import pmdp from "../data/logos/loga_pmdp.jpg";
// import mesto from "../data/logos/loga_mesto.png";
// import replast from "../data/logos/loga_replast.jpg";
import umo3 from "../data/logos/loga_umo3.jpg";
import svkpk from "../data/logos/loga_svkpk.svg";


const data = [
    {
        title: "Západočeská univerzita v Plzni",
        src: zcu
    },
    {
        title: "Koloběžky Kostka",
        src: kostka
    },
    {
        title: "PMDP",
        src: pmdp
    },
    {
        title: "ÚMO 3",
        src: umo3
    },
    // {
        // title: "Město Plzeň",
        // src: mesto
    // },
    //{
        // title: "Replast",
        // src: replast
    // },
    {
        title: "SVKPK",
        src: svkpk
    },
    {
        title: "K světu",
        src: ksvetu
    },
]

const Partners = () => {
    return(
        <Section rowClasses="align-items-center justify-content-center">
            {data.map((logo)=>{
                return(
                    <Col key={logo.title} xs="auto">
                        <img src={logo.src} alt={logo.title} style={{maxHeight:"80px"}}></img>
                    </Col>
                )
            })}
        </Section>
    );
}

export default Partners;