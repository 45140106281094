import React from "react"

import Sequence from "./Sequence";

const Tips = () => {

    const data = [
        {
            title: "Koloběžku před jízdou zkontrolujte",
            content: "Brzdy brzdí? Jsou kola dostatečně nafouknutá?"
        },
        {
            title: "Hlavně s čistou hlavou",
            content: "Stejně jako za volant, na koloběžku alkohol teké nepatří."
        },
        {
            title: "Buďte ohleduplní a respektujte ostatní",
            content: "Předvídejte možné situace a buďte ohleduplní vůči ostatním účastníkům provozu"
        },
        {
            title: "Hlavně bezpečně",
            content: "Přizpůsobte rychlost, vyhýbejte se rizikovým situacím, dodržujte bezpečný odstup a nevjíždějte bezhlavě tam, kam není vidět."
        }
    ]

    return(
        <Sequence data={data} lg={6}/>
    );
}

export default Tips;