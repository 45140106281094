import React from "react";

import {Container, Row, Col } from "react-bootstrap";

import Section from "../layout/Section";

import { FaFacebookSquare, FaEnvelope, FaPhone } from "react-icons/fa";

const Footer = (props) => {
    const links = [
        {
            icon: <FaFacebookSquare className="fs-bigger"/>,
            name: "facebook.com/scoobike",
            href: "http://www.facebook.com/scoobike"
        },
        {
            icon: <FaEnvelope className="fs-bigger"/>,
            name: "info@scoobike.cz",
            href: "mailto:info@scoobike.cz"
        },
        {
            icon: <FaPhone className="fs-bigger"></FaPhone>,
            name: "+420 702 273 811 (po-pá 8:00 do 17:00)",
            href: "tel:+420702273811"
        }
    ];
    return (
        <Section id="footer" bg="primary" color="white">
            <Container >
                <Row className="justify-center">
                    {links.map((link)=>{
                        return(<Col key={link.name} xs="auto"><a href={link.href}>{link.icon} {link.name}</a></Col>)
                    })}
                </Row>
            </Container>
        </Section>
    );
}

export default Footer;