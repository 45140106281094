import React from "react"
import { Col } from "react-bootstrap";

import Layout from "../layout/Layout";
import Section from "../layout/Section";

const Conditions = ()=>(
    <Layout title="Podmínky">
        <Section title="Všeobecné podmínky systému výpůjčky koloběžek Scoobike prostřednictvím Plzeňské karty">
            <Col xs={12}>
                <h2>I. Úvodní ustanovení</h2>
                <p>1) Tyto všeobecné podmínky systému výpůjčky koloběžek (dále jen „VP“) upravují smluvní vztah mezi účastníkem a spolkem <strong>k světu z.s.</strong>, IČ 22609814, se sídlem Pod Všemi svatými 100/69, Severní Předměstí, 301 00 Plzeň (dále jen „Půjčitel“) spočívající ve smouvě o výpůjčce koloběžky, který je mezi nimi uzavírán. Koloběžkou je koloběžka značky Kostka, která je Půjčitelem určena k výpůjčkám prostřednictvím Plzeňské karty dle těchto VP.</p>
                <p>2) Účastníkem (dále jen „Účastník“) je fyzická osoba, kterážto konkludentně (přiložením Plzeňské karty ke Stojanu (viz dále) vyjádřila souhlas s těmito VP a tímto uzavřela s Půjčitelem smlouvu o výpůjčce koloběžky, jejíž jsou tyto VP nedílnou součástí. Vypůjčit si koloběžku může pouze osoba starší 14-ti let, která má u své Plzeňské karty zaplaceno aktuálně platné předplatné jízdné nejméně na dobu 90-ti dnů (a to včetně již uběhlé nepřetržité doby předplatného jízdného do dne výpůjčky).</p>
                <p>3) Komunikace mezi Účastníkem a Půjčitelem probíhá zejména prostřednictvím internetových stránek Půjčitele www.scoobike.cz</p>
                <p>„Plzeňská karta“ je elektronická karta vydaná společností Plzeňské městské dopravní podniky a.s, IČ 25220683, se sídlem Denisovo nábřeží 920/12, Plzeň, která byla vydána na jméno Účastníka.</p>
                <h2>II. Výpůjčka koloběžky – uzavření smlouvy o výpůjčce</h2>
                <p>1) Účastník si může si od Půjčitele vypůjčit koloběžku. Koloběžku si Účastník od Půjčitele vypůjčuje a opět mu vrací prostřednictvím elektronických stojanů (dále jen „Stojany“), které jsou Půjčitelem rozmístěny na území Statutárního města Plzeň a jejichž seznam a umístění je Půjčitelem zveřejňováno na webových stránkách www.ksvetu.cz.</p>
                <p>2) K výpůjčce a uzavření dílčí smlouvy o výpůjčce mezi Půjčitelem a Účastníkem dochází tím, že se Účastník prokáže Plzeňskou kartou u příslušného Stojanu (jejím přiložením ke čtečce karet). Pakliže je ve Stojanu volná koloběžka, která je způsobilá k výpůjčce, je uvolněn zámek Stojanu, jenž drží předmětnou koloběžku, čímž je uzavřena dílčí smlouva o výpůjčce za podmínek stanovených těmito VP.</p>
                <h2>Práva a povinnosti smluvních stran z uzavřené dílčí smlouvy o výpůjčce</h2>
                <p>Uzavřením dílčí smlouvy o výpůjčce koloběžky Půjčitel přenechává Účastníkovi příslušnou koloběžku, která mu byla postupem dle čl. III odst. 2 VP uvolněna, k užívání, a to na následující dobu (dále jen „Doba výpůjčky“):</p>
                <ol>
                    <li>
                    Doba výpůjčky je maximálně 30 min, přičemž Účastník je oprávněn si koloběžku vypůjčit pouze v době mezi 7. a 20. hodinou každého dne (vrácení koloběžky je možné i mimo tyto hodiny).
                    </li>
                    <li>
                        Účastník je povinnen:
                        <p>a) Před zahájením užívání koloběžky zkontrolovat její technický stav, a to zejména upnutí koles a funkčnost brzd, a případné poškození koloběžky.  V případě technické závady či poškození je Účastník povinen tuto koloběžku bez zbytečného odkladu vrátit, či si jí vůbec nevypůjčit a bezodkladně informovat Půjčitele o této závadě, a to tím způsobem, že přidrží odpovídající tlačítko zámku na stojanu po dobu než se z modré barvy změní na červenou. Účastník není bez předchozího souhlasu Půjčitele oprávněn provádět opravy či změny na koloběžce, ať vypůjčené či jsoucí ve Stojanu.</p>
                        <p>b) Užívat vypůjčenou koloběžku způsobem odpovídajícím péči řádného hospodáře, zejména ji chránit před poškozením, nadměrným opotřebením či odcizením. Účastník je povinen se na koloběžce pohybovat pouze na území Statutárního města Plzně.</p>
                        <p>
                            c) Při jízdě na koloběžce dbát náležité opatrnosti, dodržovat pravidla provozu na pozemních komunikacích a zejména se zdržet jízdy na koloběžce pod vlivem alkoholu či jiném snížení jeho schopností bezpečně řídit koloběžku. V případě účasti Účastníka na dopravní nehodě v době, kdy má vypůjčenou koloběžku, je Účastník povinen o tomto Půjčitele bezodkladně informovat.
                        </p>
                        <p>
                            d) Vrátit koloběžku Půjčiteli nejpozději na konci Doby výpůjčky. Vrácení koloběžky provede Účastník tak, že koloběžku vrátí do volného zámku na kterémkoliv Stojanu. Účastník je povinen zkontrolovat, zda se zámek na rámu koloběžky řádně uzavřel a koloběžka nemůže být ze Stojanu volně vyňata. Pakliže toto z jakéhokoliv důvodu není možné, je Účastník povinen o tomto informovat Půjčitele a respektovat jeho přiměřené pokyny. Řádným uzavřením koloběžky v zámku Stojanu je koloběžka vrácena Půjčiteli.
                        </p>
                        <p>
                            e) Bezodkladně informovat Půjčitele o ztrátě či odcizení své Plzeňské karty, a to mailem na emailovou adresu <a href="mailto:jasmina.kahovcova@ksvetu.cz">jasmina.kahovcova@ksvetu.cz</a>, pakliže k takové ztrátě či odcizení dojde. Účastník odpovídá za škodu, která případně vznikne v důsledku porušení této povinnosti (např. odcizením koloběžky třetí osobou).
                        </p>
                        <p>f) Účastník není oprávněn vypůjčenou koloběžku umožnit užívat třetí osobě.</p>
                    </li>
                    <li>
                        Účastník odpovídá Půjčiteli za škodu, kterou způsobí porušením kterékoliv ze svých povinností. V případě porušení či porušování povinností Účastníka je Půjčitel oprávněn odmítnout do budoucna výpůjčku koloběžky příslušnému Účastníkovi.
                    </li>
                </ol>
                <h2>IV. Zajištění závazků Účastníka</h2>
                <p>1) V případě, že Účastník překročí Dobu výpůjčky dle čl. III odst. 1 této smlouvy o více jak jednu hodinu, tedy nevrátí koloběžku ani do 90-ti minut od vypůjčení, je Účastník povinen na výzvu Půjčitele zaplatit Půjčiteli smluvní pokutu ve výši 100 Kč za každou započatou půlhodinu prodlení s vrácením koloběžky. </p>
                <p>2) Půjčitel upozorňuje Účastníka, že v případě prodlení Účastníka s vrácením koloběžky, které trvá déle než 12 hodin, oznámí Půjčitel podezření ze spáchání přečinu krádeže Účastníkem Policii ČR</p>
                <p>3) Zaplacením smluvní pokuty není dotčen nárok Půjčitele na náhradu skutečné škody, příp. újmy.</p>
                <p>4) Půjčitel upozorňuje Účastníka na skutečnost, že smyslem Systému výpůjčky koloběžek je umožnit všem účastníkům krátkodobou bezplatnou dopravu koloběžkou, přičemž počet koloběžek je omezený. Vzhledem k tomu překročení délky sjednané výpůjčky tento systém velmi nabourává a s ohledem na to je v tomto článku VP stanovena a sjednána výše smluvní pokuty.</p>
                <h2>V. Osobní údaje</h2>
                <p>
                Vyjádřením souhlasu s těmito VP a zejména uzavřením smlouvy o výpůjčce, tedy přiložením své Plzeňské karty ke Stojanu bere Účastník na vědomí, že v případě porušení povinností Účastníka ze  smlouvy o výpůjčce či těchto VP, případně v případě způsobení újmy či škody v souvislosti s porušením těchto povinností, poskytne společnost Plzeňské městské dopravní podniky, a.s. Půjčiteli a společnosti Scoobike s.r.o., IČ 03600726 (která zjišťuje vymáhání nároků Půjčitele), osobní údaje o jí evidované osobě držitele příslušné Plzeňské karty (která byla ke Stojanu přiložena), které získala v souvislosti s vydáním Plzeňské karty, a to v následujícím rozsahu:
                </p>
                <ul>
                    <li>Jméno a příjmení a datum narození držitele příslušné Plzeňské karty</li>
                    <li>bydliště</li>
                    <li>telefon, email</li>
                    <li>číslo PK - identifikační osobní údaj (tj. unikátní výrobní číslo čipu)</li>
                </ul>
                <p>Uvedené osobních údaje Účastníka budou Půjčiteli poskytnuty za účelem vymáhání porušenýchpovinností a vzniklých nároků Půjčitele vůči Účastníku.</p>
                <h2>VI. Závěrečná ustanovení, změna VP, komunikace</h2>
                <p>1) Aktuální VP jsou zveřejněny na stránkách Půjčitele na internetové adrese www.ksvetu.cz. Půjčitel je oprávněn VP změnit tím způsobem, že uveřejní změnu VP na uvedené internetové adrese. Účastník se zavazuje sledovat změny VP a zejména si před uzavřením dílčí smlouvy o výpůjčce koloběžky prověřit, zda nedošlo ke změně VP. Uzavřením první dílčí smlouvy o výpůjčce následující po zveřejnění změny VP akceptuje Účastník VP ve změněném znění.</p>
                <p>2) Půjčitel archivuje VP a jejich verze.</p>
                <p>3) Veškerá oznámení a právní jednání činí:</p>
                <p>Účastník vůči Půjčiteli na následující:</p>
                <ol>
                    <li>Tel: <a href="tel:+420702273811">702 273 811</a></li>
                    <li>email: <a href="mailto:jasmina.kahovcova@ksvetu.cz">jasmina.kahovcova@ksvetu.cz</a></li>
                </ol>
                <p>4) Práva a povinnosti smluvních stran výslovně neupravená těmito VP či dílčí smlouvou o výpůjčce se řídí zákonem č. 89/2012 Sb. (občanský zákoník).</p>
                <p>5) Tyto všeobecné podmínky nabývají platnosti a vstupují v účinnost dne 17. 10. 2019</p>
                <p>V Plzni dne 16. 10. 2019</p>
                <p>k světu z.s.</p>
            </Col>
        </Section>
    </Layout>
)

export default Conditions;