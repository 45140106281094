import React from "react";

import { Modal, Button } from "react-bootstrap";
import AnchorLink from 'react-anchor-link-smooth-scroll';

import StandStatus from "./StandStatus";

const Stand = (props) => {

    const verboseBorrow = () => {
        // Assamble the necessary variables
        let num = props.data.AvailableScooters;
        let youMay = "Můžete si půjčit jednu ";
        // Return a value depending on the number of free capacity
        if (num===0) {
            return "Na tomto stanovišti teď není žádná volná koloběžka k zapůjčení.";
        }
        else if ( num === 1 ) {
            return youMay + "zbývající volnou koloběžku";
        } else if ( num > 1 && num < 5 ) {
            return youMay + "ze " + num + " volných koloběžek.";
        } else if ( num > 4 ) {
            return youMay + "z " + num + " volných koloběžek.";
        }

    };

    const verboseReturn = () => {

        // Assamble the necessary variables
        let num = props.data.FreePositionsCount;
        let youMay = "Chcete-li vrátit koloběžku, máte zde k dispozici ";
        // Return a value depending on the number of free capacity
        if (num===0) {
            return "Chcete-li vrátit vypůjčenou koloběžku, využijte prosím jiné stanoviště. Aktuálně zde není žádný volný stojan k vrácení koloběžky.";
        }
        else if ( num === 1 ) {
            return "Chcete-li vrátit koloběžku, máte zde k dispozici jeden volný stojan.";
        } else if ( num > 1 && num < 5 ) {
            return youMay + num + " volné stojany.";
        } else if ( num > 4 ) {
            return youMay + num + " volných stojanů.";
        }

    };


    return(
            <Modal show={props.data.active} onHide={props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Stanoviště č.{props.data.Name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <p>{verboseBorrow()}</p>
                        <p>{verboseReturn()}</p>
                        <StandStatus {...props.data}/>
                </Modal.Body>
                <Modal.Footer>
                    <small>
                    { props.counter }
                    </small>
                    { props.updater }
                <AnchorLink href="#jak-to-funguje" offset="100" onClick={props.onClose}>
                    <Button variant="primary">
                        Jak si půjčit koloběžku?
                    </Button>
                </AnchorLink>
                </Modal.Footer>
            </Modal>
    );
}

export default Stand;