import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import logo from '../logo.svg';
// import './App.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import Map from "./Map";
import Home from "../pages/Home";
import Downtown from "../pages/Downtown";
import Campus from "../pages/Campus";
import Conditions from "../pages/Conditions";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/centrum" component={Downtown} />
      <Route path="/kampus" component={Campus} />
      <Route path="/podminky" component={Conditions} />
    </Switch>
  );
}

export default App;
