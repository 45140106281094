import React from "react";

// import { Container, Navbar, Nav } from "react-bootstrap"
import Header from "./Header";
import Footer from "./Footer";

const Layout = ( props ) => {
    return(
        <main>
            <Header links={props.links} title={props.title} front={props.front}></Header>
            {props.children}
            <Footer></Footer>
        </main>
    );
}

export default Layout;