import React from "react";

import {Link} from "react-router-dom";

import Section from "../layout/Section";

const Obligations = () => {
    return(

        <Section>
            <p className="font-weight-bold">Povinnosti uživatele (plné znění <Link to="/podminky">zde</Link>):</p>
            <ul className="mx-3">
                <li>Vypůjčením koloběžky uživatel uzavírá Smlouvu o výpůjčce (více na www.scoobike.cz)</li>
                <li>Vypůjčením koloběžky se uživatel zavazuje k dodržování pravidel pohybu na pozemních komunikacích</li>
                <li>Před jízdou zkontrolujte technický stav koloběžky. Všimnete-li si jakékoliv závady, nepoužívejte ji!</li>
                <li>Maximální doba výpůjčky je 30 minut</li>
                <li>Za ukončenou se výpůjčka považuje v momentě, kdy uživatel vrátí koloběžku do zámku 	(zámek se při vrácení musí rozsvítit zeleně)</li>
                <li>V případě snížené viditelnosti doporučujeme opatřit oděv reflexními prvky.</li>
            </ul>
        </Section>

    );
}

export default Obligations;