import React from "react";

import { Col } from "react-bootstrap";

import SequencePartial from "./SequencePartial";

const Bulb = ( props ) => {
    const color = props.color ? "variant-" + props.color : "variant-red";
    const classes = `bulb-button ${color}`;
    const iterator = <div className={classes}></div>;
    return (
        <Col xs={12} md={6}  className="bulb">

            <SequencePartial intro={iterator} title={props.title} content={props.content} />

        </Col>
    );
}

const Bulbs = ( props ) => {
    return (
        <>
            {props.data &&
                props.data.map((bulb)=>{
                    return <Bulb key={bulb.color} {...bulb} />
                })
            }
        </>
    )
}

export default Bulbs;