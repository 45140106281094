import React from "react";
import { Link } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
// import { ScrollTo } from "react-scroll-to";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import Layout from "../layout/Layout";
import Section from "../layout/Section";
import Sequence from "../content/Sequence";
import Bulbs from "../content/Bulb";
import RegistrationCards from "../content/RegistrationCard";
import Tips from "../content/Tips";

import mapa from "../data/images/mapa.jpg";

const Campus = () => {

    // const registrationReference = React.createRef();

    const sequence = [
        {
            title: "Zaregistruj se",
            content: <>
                <p>na těchto stránkách</p>
                <AnchorLink href='#registrace' offset="100">
                    <Button variant="outline-primary" size="sm">Registrace</Button>
                </AnchorLink>
            </>
        },
        {
            title: "Najdi nejbližší stojan",
            content: <>
                <p>Výpůjční doba je 6:00 - 21:00</p>
            </>
        },
        {
            title: "Přilož kartu",
            content: <>
                <p>Rozsvítí se zámky = 20&nbsp;sekund na výběr</p>
            </>
        },
        {
            title: "Stiskni tlačítko u koloběžky",
            content: <>
                <p>Máš 10 sekund na odebrání koloběžky ze stojanu</p>
            </>
        },
        {
            title: "Vyjmi koloběžku",
        },
        {
            title: "Užij si jízdu městem!",
            content: <>
                <p>Na jízdu máš 30&nbsp;minut. A pozor! Nic za ní neplatíš :)</p>
                <AnchorLink href='#jak-jezdit' offset="100">
                    <Button variant="outline-primary" size="sm">Jak jezdit na koloběžce</Button>
                </AnchorLink>
            </>
        },
        
    ];

    const bulbs = [
        {
            color: "blue",
            title: "Vracíš koloběžku, která je v pořádku?",
            content: <p>Tak do modrého svítícího stojanu.</p>
        },
        {
            color: "red",
            title: "Vracíš porouchanou koloběžku?",
            content: <p>Tak do modře svítícího zámku a dlouze podrž tlačítko zámku, než se rozsvítí červeně.</p>
        }
    ];

    const registration = [
        {
            id: "regf",
            // header:"Plzeňská karta",
            title: <>Mám nabitou JIS&nbsp;kartu</>,
            content: <><div>Chci jezdit zdarma 30 minut + 2 hodiny pro 2 o víkendech.</div><div>Služba platí při uvedení tel. čísla a emailu</div></>,
            link: "https://portal.zcu.cz/portal/ja/jis/kolobezky.html",
            linkText: "Registrovat"
        },
        {
            id: "regfergre",
            // header: "Plzeňská karta",
            title: <>Nemám JIS&nbsp;kartu</>,
            content: <><p>Stačí mi 20 minut zdarma</p><p>Zaškrtnu soulas s jízdou na koloběžce.</p></>,
            link: "https://portal.zcu.cz/portal/ja/jis/kolobezky.html",
            linkText: "Registrovat"
        },
        {
            id: "regffr",
            // header: "Plzeňská karta",
            title: <>Nemám JIS ani Plzeňskou&nbsp;kartu</>,
            content: "kontaktujte nás na facebooku",
            link: "https://facebook.com/scoobike.cz",
            linkText: "Registrovat"
        },
    ];

    const menu = [
        <AnchorLink key="kampuz" href='#mapa' offset="100" className="nav-link">Mapa</AnchorLink>,
        <AnchorLink key="ref" href='#registrace' offset="100" className="nav-link">Registrace</AnchorLink>,
        <AnchorLink key="gerg" href='#jak-to-funguje' offset="100" className="nav-link">Jak to funguje</AnchorLink>,
        <AnchorLink key="erfg" href='#jak-jezdit' offset="100" className="nav-link">Tipy pro jízdu</AnchorLink>
    ];

    return (
        <Layout links={menu} title="Kampus ZČU">
            <img id="mapa" src={mapa} className="fit-width" alt="Mapa stanovišť s koloběžkami v kampusu ZČU"/>
            <Section id="registrace" title="Registrace" rowClasses="justify-content-center">
                <RegistrationCards data={registration} />
                <Col xs={12} className="pt-5 text-secondary"><small>Registrací souhlasíte s obchodními podmínkami, jejich plné znění naleznete na <a href="pmdp.cz" target="_blank">www.pmdp.cz</a> na <Link to="/podminky">tomto webu</Link>. Zapůjčením přebíráte zodpovědnost za
stav koloběžky, a to včetně případného zničení, ztráty, poškození či znehodnocení koloběžky
nad rámec běžného opotřebení.</small></Col>
            </Section>
            <Section id="jak-to-funguje" title="Jak to celé funguje?" bg="light">
                <Sequence data={sequence} />
                <Bulbs data={bulbs}/>
            </Section>
            <Section id="jak-jezdit" title="Jak jezdit na koloběžce" bg="white">
                <Tips />
            </Section>
        </Layout>
    );
}

export default Campus;