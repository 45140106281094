import React from "react";

import Stand from "./Stand";

import { Container, Spinner, Button } from "react-bootstrap";
import ReactTimeAgo from 'react-time-ago';

import axios  from "axios";

import { FaRedo } from "react-icons/fa";

// const defaultData = {
//     "stands": []
// };
/*
function slugify (str) {
    var map = {
        '-' : ' ',
        // '-' : '_',
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|ě|É|È|Ê|Ě',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|ů|Ú|Ù|Û|Ü|Ů',
        'c' : 'č|Č',
        'n' : 'ň|Ň',
        'r' : 'ř|Ř',
        'y' : 'ý|Ý',
        't' : 'ť|Ť',
        'd' : 'ď|Ď',
        'z' : 'ž|Ž',
        's' : 'š|Š',
    };
    
    str = str.toLowerCase();
    
    for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    };

    return str;
};
*/

class Map extends React.Component {

    constructor( props ){
        
        super( props );

        const defaultState = {
            mapLoaded: false,
            dataLoaded: false,
            currentStand: "null",
            lastUpdated: false,
            loading: true,
            data: false
        };

        this.state = defaultState;

        this.map = React.createRef();

        this.m = null;

    }

    componentDidMount(){

        // Import the scripts for the map
        this.script = document.createElement("script");
        this.script.src = "https://api.mapy.cz/loader.js";
        this.script.async = true;
        this.script.onload = () => this.onScriptLoad();
        document.body.appendChild(this.script);

        // Load the data
        // this.loadData();
    }

    async componentDidUpdate( prevProps, prevState, snapshot ){

        // Create a copy of the currentState
        let newState = Object.assign({},this.state);

        // Check kif the currentStand has changed
        if (prevState.currentStand !== newState.currentStand) {
            newState.data.stands.map((stand)=>{
                if ( stand.id === newState.currentStand ) {
                    stand.active = true;
                } else {
                    stand.active = false;
                }
                return stand;
            });
            this.setState({newState});
        }
        
    }

    async onScriptLoad() {

        if ( "Loader" in window ) {

            window.Loader.async = true;
            window.Loader.load(null,null,() => {
                this.setState({mapLoaded:true, lastUpdated: new Date()});
                this.initialiseMap();
                // console.log(map);

                // lopad the data once the api is fetched
                this.loadData();
                // this.showMarkers();

            });

        }

    }

    async loadData () {

        // const response = await fetch(

        axios.get(
            "https://loco2.zcu.cz/stand/StandInfo",{
                mode: "cors",
                headers: {
                    // "Access-Control-Allow-Origin": "http://scoobike.cz",
                    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                    "Content-Type": 'application/json',
                },
                credentials: "omit",
                referrerPolicy: "no-referrer"
            }).then(resolve => {
                // let r = resolve.json();
                return resolve.data;
                // return resolve;
            }).then(resolve=>{

                let centerX = 0;
                let centerY = 0;

                let newState = {};
                newState.stands = resolve.Stands.map((stand,index)=>{

                    centerX += stand.Latitude;
                    centerY += stand.Longitude;
                    
                    if (stand.id === this.state.currentStand) {
                        stand.active = true;
                    } else {
                        stand.active = false;
                    }

                    stand.id = "stand"+index;

                    return stand;
                });

                this.setState({ data: newState, centerX: centerX, centerY: centerY, dataLoaded: true, loading: false , lastUpdated: new Date() });
                this.showMarkers();

            });

    }

    /**
     * Start the map once only
     */
    async initialiseMap(){

        // console.log("Starting the new promise.");

        return new Promise(resolve=>{
            
            let newMap = {}
        
            /**
                if ("geolocation" in navigator ) {
                    navigator.geolocation.getCurrentPosition( ( pos ) => {
                        console.log( pos );
                    });
                }
            */

            // Get the center
            newMap.center = window.SMap.Coords.fromWGS84(13.3774611, 49.7471133);
            
            // Create the map
            newMap.map = new window.SMap( this.map.current, newMap.center, 15);
            newMap.map.addDefaultLayer( window.SMap.DEF_BASE ).enable();
            
            // Add the sync control to the map
            newMap.map.addControl( new window.SMap.Control.Sync() );

            // Add individual Controls to the map
            let o = { title:"Posun mapy" };
            let compass = new window.SMap.Control.Compass(o);
            newMap.map.addControl(compass, {right:"8px", top:"9px"});

            let z = { title: "Přiblížení mapy" };
            let zoom = new window.SMap.Control.Zoom(z);
            newMap.map.addControl(zoom, {right:"8px", top:"80px"});

            let mousePan = new window.SMap.Control.Mouse(window.SMap.MOUSE_PAN);
            newMap.map.addControl(mousePan);

            // Add the markers into two layers
            newMap.layers = {
                stands: new window.SMap.Layer.Marker(),
                self: new window.SMap.Layer.Marker(),
            };
            // Iteralte all layers, add them to the main content
            // and enable them.
            for ( let layer in newMap.layers ) {
                newMap.map.addLayer( newMap.layers[layer] );
                newMap.layers[layer].enable();
            }

            this.setState( newMap );

            this.m = newMap;

            resolve(newMap);
        });

    }

    /**
     * Draw the markers on the map
     */
    showMarkers(){

        // reset all loaded Data upon each run
        this.m.layers.stands.removeAll();

        // iterovat všechny markery
        this.state.data.stands.map(( item )=>{

            let marker = this.createMarker(item);
            
            // Finally put the marker to the map
            this.m.layers.stands.addMarker( marker );

            let cz = this.m.map.computeCenterZoom( this.state.data.stands.map(item=>{
                return new window.SMap.Coords(item.Longitude, item.Latitude);
            }), 100);

            this.m.map.setCenterZoom(cz[0],cz[1],true);

            return true;
        });
    }

    /**
     * Create a new marker.
     * @param {object} lat The object loaded from props.state
     */
    createMarker(data){
        
        // Assambe the position
        let position = window.SMap.Coords.fromWGS84(data.Longitude, data.Latitude);

        // Create the custom element
        let znacka = window.JAK.mel("div",{},{position:"relative",width:"22px", cursor: "pointer"}); 

        // Calculate the percentage
        let percent = 100 * data.FreePositionsCount/data.Capacity;
        let percentFree = percent + "%";
        let percentBlocked = (100-percent) + "%";
        
        // Create containers for both states
        let freeContainer = window.JAK.mel("div",{},{position:"absolute", right:"0px",overflow:"hidden",width:percentFree,height:"31px"}); 
        let blockedContainer = window.JAK.mel("div",{},{position:"absolute", left:"0px",overflow:"hidden",width:percentBlocked,height:"31px"}); 

        // Load the image based on the state
        var freeImage = window.JAK.mel("img", {src:window.SMap.CONFIG.img+"/marker/drop-red.png"},{position:"absolute",right:"0px",height:"31px"});
        var blockedImage = window.JAK.mel("img", {src:window.SMap.CONFIG.img+"/marker/drop-blue.png"});

        // Append images into their containers
        freeContainer.appendChild(freeImage);
        blockedContainer.appendChild(blockedImage);
        
        // Append both containers into the main marker
        znacka.append(freeContainer);
        znacka.append(blockedContainer);

        znacka.addEventListener("click",()=>{
            // console.log(data.id);
            this.setState({currentStand:data.id});
        });

        // Finally create the Marker and return it
        let id = data.Name;

        let marker = new window.SMap.Marker( position,id,{url:znacka} );
        // marker.decorate(window.SMap.Marker.Feature.Card,card);
        // console.log(marker);

        return marker;
        
    }

    updateData(){

        if ( !this.state.loading ) {
            this.setState({loading:true});
            this.loadData();
        }
        
    }


    render(){

        // Setup the updater
        let counter = false;

        if (this.state.lastUpdated !== false) {
            counter = <>
                Aktualizováno: <ReactTimeAgo date={this.state.lastUpdated} locale="cs"/>.
            </>
        }

        // Setup the reset button
        let updater = false;

        if (this.state.lastUpdated !== false) {
            updater = <Button variant="light" onClick={()=>this.updateData()}>
                {this.state.loading &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                }
                { !this.state.loading &&
                    <span><FaRedo/></span>
                }
            </Button>
        }

        return (
            <Container fluid id="mapa">
                <section>
                        <div id="react-map" ref={ this.map } className="mapa" style={{height:"70vh",width:"100%",overflow:"hidden"}}>
                        </div>
                        
                </section>
                <div>
                        { this.state.data &&
                            this.state.data.stands.map((stand)=>{
                                // console.log(stand);
                            return <Stand key={stand.Name} data={stand} counter={counter} updater={updater} onClose={()=>{
                                this.setState({currentStand:"null"});
                            }}/>
                        })}
                </div>
            </Container>
        );

    }

}


export default Map